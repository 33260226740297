<button
  class="search-view-button"
  [class]="{ 'search-view-button--selected-view': !!selectedSearchView() }"
  mat-flat-button
  [matMenuTriggerFor]="searchViewListMenu"
  (click)="trackSearchViewButtonClick(); $event.stopPropagation()"
>
  <mat-icon>playlist_add_check</mat-icon>
  <span class="search-view-button__label">{{ selectedSearchView()?.name || 'Ansichten' }}</span>
  <mat-icon>arrow_drop_down</mat-icon>
</button>

<mat-menu #searchViewListMenu class="{{ class }}__menu">
  <ng-template matMenuContent>
    <mp-search-view-menu
      [searchViews]="searchViews()"
      [selectedSearchViewId]="selectedSearchView()?.searchViewId"
      [insightsEventsConfig]="insightsEventsConfig()"
      (searchViewSelect)="selectSearchView($event)"
      (searchViewCreate)="createSearchView($event)"
      (searchViewUpdate)="updateSearchView($event)"
      (searchViewDelete)="deleteSearchView($event)"
    />
  </ng-template>
</mat-menu>
