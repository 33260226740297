import { NgModule } from '@angular/core';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS, MatLegacyDialogConfig } from '@angular/material/legacy-dialog';

import { InfoDialogComponent } from './info-dialog.component';

const DEFAULT_DIALOG_CONFIG: MatLegacyDialogConfig = {
  panelClass: 'mp-info-dialog-container',
};

@NgModule({
  imports: [InfoDialogComponent],
  exports: [InfoDialogComponent],
  providers: [{ provide: MAT_LEGACY_DIALOG_DEFAULT_OPTIONS, useValue: DEFAULT_DIALOG_CONFIG }],
})
export class InfoDialogModule {}
