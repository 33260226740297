<div mat-dialog-title class="dialog-title">
  <span class="dialog-title__label">
    @if (data.hasIcon) {
      <mat-icon class="dialog-title__icon">check</mat-icon>
    }
    {{ data.title }}
  </span>

  <button mat-icon-button>
    <mat-icon mat-dialog-close>close</mat-icon>
  </button>
</div>

<div mat-dialog-content class="dialog-content">
  {{ data.message }}
</div>

<div mat-dialog-actions class="dialog-actions" [align]="'end'">
  <button mat-flat-button color="accent" mat-dialog-close>Schließen</button>
</div>
