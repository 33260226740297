import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';

import { InfoDialogData } from './info-dialog-data';

@Component({
  standalone: true,
  selector: 'mp-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrl: './info-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyButtonModule, MatIconModule, MatLegacyDialogModule],
})
export class InfoDialogComponent {
  readonly data: InfoDialogData = inject<InfoDialogData>(MAT_LEGACY_DIALOG_DATA);
}
