import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatLegacyCardModule } from '@angular/material/legacy-card';

import { TableItemsInfoComponent } from '../table-items-info/table-items-info.component';

@Component({
  selector: 'mp-tab-overview',
  standalone: true,
  templateUrl: './tab-overview.component.html',
  styleUrl: './tab-overview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyCardModule, TableItemsInfoComponent],
})
export class TabOverviewComponent {
  readonly overviewTitle: InputSignal<string> = input.required<string>();

  readonly totalItems: InputSignal<number | undefined> = input<number>();
}
