import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  InputSignal,
  Optional,
  OutputEmitterRef,
  input,
  output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { InsightsEvent } from '@mp/shared/app-insights/domain';
import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';

import { SearchView, SearchViewInsightsEventsConfig, SearchViewNameUpdate } from '../../models';
import { SearchViewMenuComponent } from '../search-view-menu/search-view-menu.component';

@Component({
  selector: 'mp-search-view',
  standalone: true,
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatMenuModule, MatIconModule, SearchViewMenuComponent],
})
export class SearchViewComponent<T> {
  @HostBinding() readonly class = 'mp-search-view';

  readonly searchViews: InputSignal<SearchView<T>[]> = input.required<SearchView<T>[]>();

  readonly selectedSearchView: InputSignal<SearchView<T> | undefined> = input.required<SearchView<T> | undefined>();

  readonly insightsEventsConfig: InputSignal<SearchViewInsightsEventsConfig | undefined> =
    input<SearchViewInsightsEventsConfig>();

  readonly searchViewSelect: OutputEmitterRef<SearchView<T>> = output<SearchView<T>>();

  readonly searchViewCreate: OutputEmitterRef<string> = output<string>();

  readonly searchViewUpdate: OutputEmitterRef<SearchViewNameUpdate> = output<SearchViewNameUpdate>();

  readonly searchViewDelete: OutputEmitterRef<string> = output<string>();

  constructor(@Optional() private readonly insightsEventsTrackingService: InsightsEventsTrackingService | null) {}

  selectSearchView(searchView: SearchView<T>): void {
    this.searchViewSelect.emit(searchView);
  }

  createSearchView(searchViewName: string): void {
    this.searchViewCreate.emit(searchViewName);
  }

  updateSearchView(updatedSearchView: SearchViewNameUpdate): void {
    this.searchViewUpdate.emit(updatedSearchView);
  }

  deleteSearchView(searchViewId: string): void {
    this.searchViewDelete.emit(searchViewId);
  }

  trackSearchViewButtonClick(): void {
    const event: InsightsEvent | undefined = this.insightsEventsConfig()?.searchViewButtonClick;
    if (!event) {
      return;
    }

    this.insightsEventsTrackingService?.trackEvent(event);
  }
}
