import { ComponentType } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, InputSignal, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'mp-dialog-test',
  standalone: true,
  template: ' <button mat-raised-button (click)="onClick()">Open dialog</button> ',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule],
})
export class DialogTestComponent<T, DataType = unknown> {
  readonly componentType: InputSignal<ComponentType<T>> = input.required<ComponentType<T>>();
  readonly dialogData: InputSignal<DataType> = input<DataType>({} as DataType);
  readonly dialog: MatLegacyDialog = inject(MatLegacyDialog);

  onClick(): void {
    this.dialog.open<T, DataType>(this.componentType(), { data: this.dialogData() });
  }
}
