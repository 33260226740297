import { ChangeDetectionStrategy, Component, InputSignal, ModelSignal, input, model } from '@angular/core';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';

import { FilterBadgeComponent } from '../filter-badge';

import { ContextTab } from './context-tab';

@Component({
  selector: 'mp-context-tabs',
  standalone: true,
  templateUrl: './context-tabs.component.html',
  styleUrl: './context-tabs.component.scss',
  host: {
    class: 'mp-context-tabs',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyTabsModule, FilterBadgeComponent],
})
export class ContextTabsComponent<T> {
  readonly contextTabs: InputSignal<ContextTab<T>[]> = input.required<ContextTab<T>[]>();

  readonly selectedTab: ModelSignal<T | undefined> = model<T | undefined>();
}
